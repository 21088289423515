<template>
  <addField
    path="serve.php?f=testing&f2=searchTests"
    :additionalAttributes="{function: 'searchForTestCases'}"
    :dialogAttributes="{}"
    v-on="$listeners"
    :showDialog="showDialog"
  >
    <slot>
      <v-icon>mdi-plus</v-icon>
    </slot>
  </addField>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  props: ["showDialog"],
  components: { addField },
};

// function searchTestCase(caller) {
//   var callerId = "";
//   var $caller = $(caller);
//   $caller.uniqueId();
//   callerId = $caller.attr("id");
//   //var called=false;
//   var callBackFunction = function ($dialog) {
//     var $tableSelector = $dialog.find("#popupTable");
//     //console.log($tableSelector);
//     // if (called==false){
//     //called=true;
//     //loadDataTable($tableSelector,false);

//     //  console.log("done");
//     var maxheight = calculateWindowMaxHeight();
//     $dialog
//       .find(".scrolling")
//       .css("max-height", maxheight)
//       .css("overflow-y", "auto");
//     $dialog
//       .find(".dataTable")
//       .closest(".editDialog")
//       .css("max-height", maxheight)
//       .css("overflow-y", "auto");
//     //}
//     $tableSelector.on("click", "tr", function () {
//       var $tr = $(this);
//       var name = $tr
//         .find('[data-name="c_name"]')
//         .find(".tablefieldContent")
//         .text();
//       if (name != "") {
//         //Avoid Empty
//         //var callerId = $tr.closest(".editDialog").attr("data-callerID"); No idea why this guy did like this !!
//         var callerId = $tr.closest(".ui-dialog").attr("data-callerID");
//         var testId = $tr.attr("data-id");
//         var deleteOption =
//           "<a href='javascript:void(0);' title='Delete test' onclick='removeTest(this)' class='deleteSign' ><i class='fas fa-trash fs16 iconRed' aria-hidden='true'></i></a>";
//         var $caller = $("#" + callerId);
//         //$caller.text(name);
//         var $runControl = $caller
//           .closest(".onErrorContainer")
//           .find(".testErrorText");
//         $runControl.text(name);
//         $caller
//           .closest(".onErrorContainer")
//           .find(".deleteContainer")
//           .html(deleteOption);
//         $caller.closest(".onErrorContainer").find(".onErrorId").val(testId);

//         closeDialog($tr);
//       }
//     });
//   };
//   var ajaxOptions = { function: "searchForTestCases" };
//   var $returnedDialog = getPopupDialog(
//     caller,
//     "serve.php?f=testing&f2=searchTests",
//     0,
//     { width: 1400 },
//     ajaxOptions,
//     callBackFunction,
//     "Search Tests"
//   );

//   $returnedDialog = dialogStackUp($caller, $returnedDialog);
//   $returnedDialog.attr("data-callerID", callerId);
// }
</script>