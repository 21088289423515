<template>
    <v-icon
      @click.stop="checkClick($event)"
      @mouseenter="$emit('mouseenter', 'trash')"
      @mouseleave="$emit('mouseleave', 'trash')"
      title="Delete"
      :size="size"
      color="red"
      :disabled="disabled"
      :id="localId"
      >mdi-delete</v-icon
    >
</template>
<script>
export default {
  props: {
    size: Number,
    result: {},
    id: {},
    fieldAttributes: {},
    field: {},
  },
  computed: {
    localId() {
      let localId;
      if (typeof this.id != "undefined") {
        localId = this.id;
      } else if (
        typeof this.fieldAttributes != "undefined" &&
        typeof this.fieldAttributes.fieldAttrInput.id != "undefined"
      ) {
        localId = this.fieldAttributes.fieldAttrInput.id;
      }
      return localId;
    },
    disabled() {
      let disabled = false;
      //console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    checkClick() {
      this.$emit("click", "trash");
      this.$emit("trash-clicked");
    },
  },
};
</script>