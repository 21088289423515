<template>
  <div class="onErrorContainer ma-2">
    <v-row no-gutters>
      <v-col style="max-width: 150px">
        {{ fieldAttributes.name }}
        <input type="hidden" :name="field" :value="id" />
      </v-col>
      <v-col style="max-width: 200px">
        <div  v-if="typeof result.disableAllInputs!='undefined' && result.disableAllInputs==true">
            {{ cname }}
        </div>
        <searchTestCase
         v-else
          @row-clicked="checkRowClicked"
          :showDialog="showDialog"
          @dialog-closed="dialogClose"
          @dialog-loaded="dialogClose"
          :disabled="result.disableAllInputs"
        >{{ cname }}
        </searchTestCase>
      </v-col>
      <v-col style="max-width: 50px">
        <trash
                @click="removeConfigured()"
                :style="typeof result.disableAllInputs!='undefined' && result.disableAllInputs==true?'color:grey!important':''"></trash>
      </v-col>
    </v-row>
    <span class="deleteContainer">
      <a
        v-if="result.additional.testNameArray[field] != 'Not Configured'"
        href="javascript:void(0);"
        title="Delete test"
        onclick="removeTest(this);"
        class="deleteSign">
        <i class="fas fa-trash fs16 iconRed" aria-hidden="true"></i>
      </a>
    </span>
  </div>
</template>
<script>
import trash from "@/commonComponents/dynamic/trash.vue";
import searchTestCase from "@/components/legacy/searchTestCase.vue";
export default {
  data() {
    return {
      cname: this.result.additional.testNameArray[this.field],
      showDialog: false,
    };
  },
  components: { searchTestCase, trash },
  props: ["result", "fieldAttributes", "field","value"],
  computed: {
    id() {
      return this.value;
    },
  },
  methods: {
    removeConfigured() {
      this.$emit("input", "");
      this.cname =  "Not Configured";
    },
    dialogClose(value) {
      this.showDialog = value;
    },
    checkRowClicked(id, name) {
      this.cname = name;
      console.log("rowClicked", id, name, this.showDialog);
      this.showDialog = false;
      this.$emit("input", id);
    },
  },
};
</script>